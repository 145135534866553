<!--电网状态-->

<template>
    <widget-framework v-if="dataLoaded" title="电网状态" :show-border="true" @click="onTitleClick">
        <template #content>
            <div style="height: 80%;">
                <v-chart :options="opt" :autoresize="true" style="width: 100%; height: 100%;"></v-chart>
            </div>
            <div style="height: 20%;display: flex;flex-direction: column">
                <dv-border-box-8 class="operation-days">
                    <span class="title">本年电网稳定运行天数：</span>
                    <span class="value">
                        <span class="number">{{stableOperationDays}}</span>
                        天
                    </span>
                </dv-border-box-8>
            </div>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import WidgetBase from '@/components/widgets/WidgetBase';
import EchartBase from '@/components/widgets/EchartBase';
import numeral from 'numeral';
import {
    yellow,
    blue,
    white,
    green,
    tooltip,
    lightGrey,
    filledCircle,
    whiteOpacity, cyan, purple,
} from '../constant';

export default {
    name: 'PowerGridStatus',
    components: {
        WidgetFramework
    },
    mixins: [WidgetBase, EchartBase],
    inject: ['bigScreen'],
    methods: {
        onTitleClick() {
            this.bigScreen.currentIndex = 2;
        }
    },
    data() {
        return {
            dataLoaded: false,
            opt: {
                textStyle: {
                    color: lightGrey
                },
                tooltip: {
                    trigger: 'item',
                    backgroundColor: tooltip.backgroundColor,
                    borderColor: tooltip.borderColor,
                    borderWidth: tooltip.borderWidth,
                    extraCssText: tooltip.extraCssText
                },
                dataset: [],
                series: [
                    {
                        name: '电网负荷率',
                        type: 'pie',
                        radius: [0, '50%'],
                        hoverAnimation: false,
                        itemStyle: {
                            color: filledCircle
                        },
                        label: {
                            show: true,
                            position: 'center',
                            formatter: (params) => {
                                const {value} = params.data;
                                return `{a|${numeral(value).format('0%')}}\n{b|电网负荷率}`;
                            },
                            rich: {
                                a: {
                                    color: yellow,
                                    fontSize: 20,
                                    height: 30,
                                    fontWeight: 'bold'
                                },
                                b: {
                                    color: white,
                                    fontSize: 10
                                }
                            }
                        },
                        labelLine: {
                            show: false,
                        },
                        datasetIndex: 0,
                        encode: {
                            value: 'value'
                        },
                        tooltip: {
                            formatter: (params) => {
                                const {value} = params.data;
                                return `电网负荷率：${numeral(value).format('0%')}`;
                            }
                        }
                    },
                    {
                        name: '负荷',
                        type: 'pie',
                        radius: ['45%', '60%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: true,
                            position: 'outside',
                            formatter: (params) => {
                                const {name, value} = params.data;
                                return `{a|${value}kVA\n${name}}`;
                            },
                            alignTo: 'labelLine',
                            lineHeight: 14,
                            rich: {
                                a: {
                                    color: white,
                                    fontSize: 10
                                }
                            }
                        },
                        labelLine: {
                            show: true,
                            length2: 40,
                        },
                        emphasis: {
                            label: {
                                show: false,
                            },
                        },
                        datasetIndex: 1,
                        encode: {
                            value: 'value'
                        },
                        tooltip: {
                            formatter: (params) => {
                                const {name, value} = params.data;
                                return `${name}：${value}kVA`;
                            }
                        }
                    },
                    {
                        type: 'pie',
                        radius: ['70%', '73%'],
                        legendHoverLink: false,
                        hoverAnimation: false,
                        itemStyle: {
                            color: whiteOpacity
                        },
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [1],
                        markPoint: {
                            symbol: 'none'
                        },
                        tooltip: {
                            show: false,
                        },
                        z: 0
                    },
                    {
                        type: 'pie',
                        radius: ['80%', '86%'],
                        legendHoverLink: false,
                        hoverAnimation: false,
                        itemStyle: {
                            color: whiteOpacity
                        },
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [1],
                        markPoint: {
                            symbol: 'none'
                        },
                        tooltip: {
                            show: false,
                        },
                        z: 0
                    }
                ]
            },
            stableOperationDays: null
        };
    },
    mounted() {
        this.$client.getPowerGridStatusInfo().then((ret) => {
            const {data = []} = ret;
            const dataset1 = [];
            //模拟数据，取一天电网符合
            data.currentCapacity = 2270;
            dataset1.push({name: '剩余负荷', value: data.totalCapacity - data.currentCapacity, itemStyle: {color: blue}});
            // dataset1.push({name: '电网总负荷', value: data.totalCapacity, itemStyle: {color: green}});
            dataset1.push({name: '当前电网负荷', value: data.currentCapacity, itemStyle: {color: yellow}});

            let rate = data.currentCapacity / data.totalCapacity;
            const dataset = [];
            dataset.push({source: [{name: '电网负荷率', value: rate}]});
            dataset.push({source: dataset1});
            this.opt.dataset = dataset;
            this.stableOperationDays = data.stableOperationDays;
            this.dataLoaded = true;
        });
    }
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.operation-days {
    width: 200px;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    margin:auto;

    .title {
        color: @green;
        font-size: 1vh;
    }
    .value {
        color: @yellow;
        font-size: 1vh;
        .number {
            font-size: 2vh;
            font-weight: bold;
        }
    }
}

</style>
